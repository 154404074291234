<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("generic.filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <label>{{ $t('generic.search') }}</label>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="query.search"
              debounce="1000"
              :placeholder="$t('generic.search')"
            />
          </b-input-group>
        </b-col>
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-1"
        >
          <label for="club-country-id">{{ $t('generic.country') }}</label>
          <v-select
            id="club-country-id"
            v-model="query.idCountry"
            class="select2-secondary"
            label="name"
            :options="countries"
            :reduce="country => country.id"
            :placeholder="$t('generic.selectCountry')"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label for="club-state-id">{{ $t('generic.state') }}</label>
          <v-select
            id="club-state-id"
            v-model="query.idState"
            label="name"
            :options="states"
            :disabled="query.idCountry == null"
            :reduce="state => state.id"
            :placeholder="$t('generic.selectState')"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
        <b-col
          v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('corporate.club') }}</label>
          <v-select
            v-model="query.idClub"
            :placeholder="$t('corporate.selectSportClub')"
            label="name"
            :options="clubs"
            :reduce="club => club.id"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t("generic.status") }}</label>
          <v-select
            v-model="query.status"
            label="text"
            :placeholder="$t('generic.selectStatus')"
            :options="options"
            :reduce="item => item.value"
          >
            <div slot="no-options">
              {{ $t('generic.noRecordsFound') }}
            </div>
          </v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import servicesCountry from '@/services/countryService'
import servicesClubs from '@/services/clubsService'

export default {
  components: {
    vSelect,
  },

  props: {
    countries: {
      type: Object / Array,
      required: true,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      query: {
        idCountry: null,
        idState: null,
        idClub: null,
        status: null,
        search: null,
      },
      clubs: [],
      states: [],
      options: [],
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.assignOptions()
    },
    'query.idCountry': function queryIdCountry(pCountry, pOld) {
      if (pCountry != null) {
        if (pOld != null) {
          if (pOld != pCountry) {
            this.clearStateAndClub()
            this.getStates(pCountry)
            this.getClubs()
          }
        } else {
          this.clearStateAndClub()
          this.getStates(pCountry)
          this.getClubs()
        }
      } else {
        this.clearStateAndClub()
      }
      this.$emit('filter-query', this.query)
    },
    'query.idState': function queryIdState(pState, pOldS) {
      if (pState != null) {
        if (pOldS != null) {
          if (pOldS != pState) {
            this.clearState()
          }
        } else {
          this.clearState()
        }
      } else {
        this.clearState()
      }
      this.$emit('filter-query', this.query)
    },
    'query.idClub': function queryIdClub() {
      this.$emit('filter-query', this.query)
    },
    'query.status': function queryStatus() {
      this.$emit('filter-query', this.query)
    },
    'query.search': function querySearch() {
      this.$emit('filter-query', this.query)
    },
  },

  mounted() {
    this.assignOptions()
  },

  methods: {
    assignOptions() {
      this.options = [
        { value: '1', text: this.$t('generic.active') },
        { value: '0', text: this.$t('generic.inactive') },
        { value: '2', text: this.$t('generic.deleted') },
      ]
    },
    clearStateAndClub() {
      this.query.idState = null
      this.states = []
      this.query.idClub = null
      this.clubs = []
    },
    clearState() {
      this.query.idClub = null
      this.clubs = []
      this.getClubs()
    },
    getStates(countryHash) {
      const filter = { country_hash: countryHash }
      servicesCountry.getStates(filter)
        .then(({ data }) => {
          this.states = data.data.map(e => ({ id: e.hash, name: e.name }))
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    getClubs() {
      const filter = {
        status: 1,
        country_hash: this.query.idCountry,
        state_hash: this.query.idState,
      }
      servicesClubs.getClubs({}, filter)
        .then(({ data }) => {
          this.clubs = data.data.map(e => ({ id: e.hash, name: e.club_name }))
        }).catch(error => {
          this.responseCatch(error)
        })
    },
  },
}
</script>
