<template>
  <div>
    <span class="mr-20">{{ $t('roles.createNewRol') }}</span>
    <b-button
      variant="primary"
      class="btn-icon rounded-circle"
      :style="colorPrimaryBtn"
      @click="showRolModal = true"
    >
      <feather-icon
        icon="PlusIcon"
      />
    </b-button>
    <b-modal
      id="createRolModal"
      v-model="showRolModal"
      centered
      ok-only
      :title="$t('roles.createNewRol')"
      no-close-on-backdrop
      @close="clearForm"
    >
      <validation-observer ref="createRolRules">
        <b-form ref="formRegister">
          <!-- Club -->
          <b-form-group v-if="!checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
            <b>{{ $t('corporate.club') }}: </b> <label>{{ clubInfo.club_name }} </label>
          </b-form-group>
          <!-- Name -->
          <b-form-group>
            <label for="name">{{ $t('generic.name') }} <span class="text-danger">*</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :name="$t('generic.name')"
            >
              <b-form-input
                id="name"
                v-model="rol.name"
                type="text"
                :placeholder="$t('roles.enterRoleName')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-row>
            <!-- Is owner -->
            <b-col>
              <b-form-group v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
                <label> {{ $t('generic.isAdminClub') }} </label><br>
                <label> {{ $t('generic.not') }} </label>
                <b-form-checkbox
                  v-model="rol.is_admin_club"
                  :disabled="Boolean(rol.is_internal)"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  inline
                  class="custom-control-primary pl-1"
                >
                  <label class="pb-1 pl-1"> {{ $t('generic.yes') }} </label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <!-- Is external -->
            <b-col>
              <b-form-group v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
                <label> {{ $t('generic.isExternal') }} </label><br>
                <label> {{ $t('generic.not') }} </label>
                <b-form-checkbox
                  v-model="rol.is_internal"
                  :value="1"
                  :unchecked-value="0"
                  switch
                  inline
                  class="custom-control-primary pl-1"
                >
                  <label class="pb-1 pl-1"> {{ $t('generic.yes') }} </label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Country -->
          <b-form-group v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
            <label for="club-country-id">
              {{ $t('generic.country') }}
              <span
                v-show="!Boolean(rol.is_internal)"
                class="text-danger"
              >
                *
              </span>
            </label>
            <validation-provider
              #default="{ errors }"
              :rules="`${Boolean(rol.is_internal) ? '' : 'required'}`"
              :name="$t('generic.country')"
            >
              <v-select
                id="club-country-id"
                v-model="options.country_id"
                label="name"
                :options="countries"
                :reduce="(country) => country.id"
                :placeholder="$t('generic.selectCountry')"
                :disabled="Boolean(rol.is_internal)"
              >
                <div slot="no-options">
                  {{ $t('generic.noRecordsFound') }}
                </div>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- State -->
          <b-form-group v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
            <label for="club-state-id">
              {{ $t('generic.state') }}
              <span
                v-show="!Boolean(rol.is_internal)"
                class="text-danger"
              >
                *
              </span>
            </label>
            <validation-provider
              #default="{ errors }"
              :rules="`${Boolean(rol.is_internal) ? '' : 'required'}`"
              :name="$t('generic.state')"
            >
              <v-select
                id="club-state-id"
                v-model="options.state_id"
                label="name"
                :options="states"
                :disabled="options.country_id == null || Boolean(rol.is_internal)"
                :reduce="(states) => states.id"
                :placeholder="$t('generic.selectState')"
              >
                <div slot="no-options">
                  {{ $t('generic.noRecordsFound') }}
                </div>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Club -->
          <b-form-group v-if="checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])">
            <label>
              {{ $t('corporate.club') }}
              <span
                v-show="!Boolean(rol.is_internal)"
                class="text-danger"
              >
                *
              </span>
            </label>
            <validation-provider
              #default="{ errors }"
              :rules="`${Boolean(rol.is_internal) ? '' : 'required'}`"
              :name="$t('corporate.club')"
            >
              <v-select
                v-model="rol.club_id"
                :placeholder="$t('corporate.selectSportClub')"
                label="name"
                :options="clubs"
                :disabled="options.state_id == null || Boolean(rol.is_internal)"
                :reduce="(club) => club.id"
              >
                <div slot="no-options">
                  {{ $t('generic.noRecordsFound') }}
                </div>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>

      <template #modal-footer>
        <div class="float-right">
          <b-button
            variant="secondary"
            pill
            @click="resetForm"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-overlay
            :show="isLoadingCreate"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              v-if="checkPermission(['CREATE_ROLES', 'ALLOW_ALL'])"
              variant="primary"
              style="margin-left: 17px;"
              :style="colorPrimaryBtn"
              pill
              @click="saveRoleData()"
            >
              {{ $t('buttons.save') }}
            </b-button>
          </b-overlay>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import { required } from '@validations'
import servicesCountry from '@/services/countryService'
import servicesClubs from '@/services/clubsService'
import servicesRole from '@/services/roleService'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    countries: {
      type: Object / Array,
      required: true,
      default: null,
    },
    clubInfo: {
      type: Object,
      required: true,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showRolModal: false,
      isLoadingCreate: false,
      rol: {
        name: null,
        is_admin_club: 0,
        is_internal: 0,
        club_id: null,
      },
      required,
      filters: {
        page: 1,
        perPage: 15,
        idClub: null,
      },
      options: {
        country_id: null,
        state_id: null,
      },
      states: [],
      clubs: [],
    }
  },
  computed: {
    ...mapGetters({
      colorPrimaryBtn: 'colorPrimaryBtn',
      colorRGB: 'colorRGBX',
    }),
  },
  watch: {
    'rol.is_internal': function (pInternal) {
      if (pInternal === 1) {
        this.options.country_id = null
        this.options.state_id = null
        this.rol.club_id = null
        this.rol.is_admin_club = 0
      }
    },
    'options.country_id': function (pCountry, pOld) {
      if (pCountry != null) {
        if (pOld != null) {
          if (pOld != pCountry) {
            this.states = []
            this.options.state_id = null
            this.getStates(pCountry)
          }
        } else {
          this.states = []
          this.options.state_id = null
          this.getStates(pCountry)
        }
      } else {
        this.states = []
        this.options.state_id = null
      }
    },
    'options.state_id': function (pState, pOldS) {
      if (pState != null) {
        if (pOldS != null) {
          if (pOldS != pState) {
            this.clubs = []
            this.rol.club_id = null
            this.getClubs(pState)
          }
        } else {
          this.clubs = []
          this.rol.club_id = null
          this.getClubs(pState)
        }
      } else {
        this.clubs = []
        this.rol.club_id = null
      }
    },
  },
  methods: {
    getStates(countryHash) {
      const filter = { country_hash: countryHash }
      servicesCountry.getStates(filter).then(({ data }) => {
        this.states = data.data.map(e => ({ id: e.hash, name: e.name }))
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    getClubs(stateHash) {
      const filter = {
        state_hash: stateHash,
        status: 1,
      }
      servicesClubs.getClubs({}, filter).then(({ data }) => {
        this.clubs = data.data.map(e => ({ id: e.hash, name: e.club_name }))
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    saveRoleData() {
      if (this.rol.club_id == null) {
        this.rol.club_id = this.clubInfo.hash
      }
      this.$refs.createRolRules.validate().then(isValid => {
        if (isValid) {
          this.isLoadingCreate = true
          servicesRole.storeRole(this.rol).then(({ data }) => {
            this.$emit('successfully')
            this.responseSuccessCreate(data.message)
            this.resetForm()
            this.clearForm()
            this.isLoadingCreate = false
          }).catch(error => {
            this.isLoadingCreate = false
            this.responseCatch(error)
          })
        }
      })
    },
    resetForm() {
      this.showRolModal = false
      this.filters.idClub = this.clubInfo.hash
    },
    clearForm() {
      this.rol.name = null
      this.rol.is_admin_club = 0
      this.rol.is_internal = 0
      this.rol.club_id = null
      this.options.country_id = null
      this.options.state_id = null
    },
  },
}
</script>
<style scoped lang="scss">
.mr-20 {
  margin-right: 20px !important;
}
</style>
